import React from "react";
import { Routes, Route } from "react-router-dom";
import Register from "./routes/Register";
import Callback from "./routes/Callback";
import Contracts from "./routes/Contracts";

const BaseRouter = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Register />} />
        <Route path="/eve-auth/callback" element={<Callback />} />
        <Route path="/contracts" element={<Contracts />} />
      </Routes>
    </div>
  );
};

export default BaseRouter;

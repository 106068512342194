import React, { useState } from "react";
import { postData } from "../utils/fetch";
import millify from "millify";
import settings from "../evehobbes.client.settings";

const EvePraisal = (props) => {
  const { contract, contractItems, evePraisal, setEvePraisal } = props;

  const generateEvepraisal = async () => {
    try {
      const evepraisalJson = {
        market_name: "jita",
        items: contractItems
      };

      const url = `${settings.server_endpoint}/eve-industry/appraisal`;

      const response = await postData(url, evepraisalJson);
      console.log(response);
      const { appraisal } = response;
      setEvePraisal(appraisal);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="contract-field">
        <button className="contract-copy-button" onClick={generateEvepraisal}>
          Generate Evepraisal
        </button>
      </div>
      {evePraisal && (
        <>
          <div className="contract-field">
            <span className="contract-key">Jita Split:</span>
            <span className="contract-value">
              ISK{" "}
              {millify((evePraisal.totals.buy + evePraisal.totals.sell) / 2, {
                precision: 2
              })}
            </span>
          </div>
          <div className="contract-field">
            <span className="contract-key">Jita Buy:</span>
            <span className="contract-value">
              ISK {millify(evePraisal.totals.buy, { precision: 2 })}
            </span>
          </div>
          <div className="contract-field">
            <span className="contract-key">Jita Sell:</span>
            <span className="contract-value">
              ISK {millify(evePraisal.totals.sell, { precision: 2 })}
            </span>
          </div>{" "}
        </>
      )}
    </>
  );
};

export default EvePraisal;

import eveSso from "../assets/eve-sso.png";
import { EVE_SCOPES, EVE_STATE } from "../constants";
import settings from "../evehobbes.client.settings";

const Register = () => {
  const redirectToEveSso = () => {
    const redirectUrlBase = "https://login.eveonline.com/v2/oauth/authorize/";
    const responseType = "response_type=code";
    const redirectUri = `redirect_uri=${settings.server_endpoint}/eve-auth/callback/`;
    const clientId = `client_id=${settings.client_id}`;
    const scope = `scope=${EVE_SCOPES.join("%20")}`;
    const state = `state=${EVE_STATE}`;

    const redirectUrl = new URL(
      redirectUrlBase +
        "?" +
        responseType +
        "&" +
        redirectUri +
        "&" +
        clientId +
        "&" +
        scope +
        "&" +
        state
    );

    window.location.href = redirectUrl;
  };

  return (
    <div className="App">
      <header className="App-header">
        <button onClick={redirectToEveSso} style={{ cursor: "pointer" }}>
          <img src={eveSso} alt="eve-sso" />
        </button>
      </header>
    </div>
  );
};

export default Register;

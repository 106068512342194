import React, { useEffect, useState } from "react";
import millify from "millify";
import ClipboardCopy from "../components/ClipboardCopy";
import EvePraisal from "../components/EvePraisal";
import { getData } from "../utils/fetch";
import settings from "../evehobbes.client.settings";

const Contracts = () => {
  const [contract, setContract] = useState();
  const [contractItems, setContractItems] = useState();
  const [issuer, setIssuer] = useState();
  const [issuingCorporation, setIssuingCorporation] = useState();
  const [structure, setStructure] = useState();
  const [copyText, setCopyText] = useState();
  const [evePraisal, setEvePraisal] = useState();

  const params = new URLSearchParams(document.location.search);
  const id = params.get("contract_id");
  const [contract_id, setContractId] = useState(id);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${settings.server_endpoint}/eve-industry/contracts/${contract_id}/items`;
      const data = await getData(url);
      const { contract, contractItems, issuer, issuingCorporation, structure } =
        data;
      const itemsList = contractItems
        .map((item) => ({ name: item.name, quantity: item.quantity }))
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

      let copyText = ``;
      itemsList.forEach((item) => {
        copyText += `${item.name} x${item.quantity}\n`;
      });

      setCopyText(copyText);

      setContract(contract);
      setContractItems(itemsList);
      setIssuer(issuer);
      setIssuingCorporation(issuingCorporation);
      setStructure(structure);
    };
    if (contract_id) {
      fetchData();
    }
  }, [contract_id]);

  const priceStyle = () => {
    let style = {};
    let color;
    if (evePraisal) {
      if (
        evePraisal.totals.buy < contract.price &&
        contract.price < evePraisal.totals.sell
      ) {
        color = "#0D41EF";
      } else if (evePraisal.totals.buy > contract.price) {
        color = "#11EF0D";
      } else if (evePraisal.totals.sell < contract.price) {
        color = "#EF2B0D";
      }
      style = { ...style, color: color };
    }
    return style;
  };

  const priceIndicator = () => {
    let indicator;
    if (evePraisal) {
      if (
        evePraisal.totals.buy < contract.price &&
        contract.price < evePraisal.totals.sell
      ) {
        indicator = "↔";
      } else if (evePraisal.totals.buy > contract.price) {
        indicator = "↓";
      } else if (evePraisal.totals.sell < contract.price) {
        indicator = "↑";
      }
    }
    return indicator;
  };

  const renderContract = () => {
    const style = priceStyle();
    const indicator = priceIndicator();
    if (contract && contractItems && issuer && issuingCorporation) {
      return (
        <div className="contract">
          <div className="contract-details">
            <header className="contract-header">
              {contract.title}&emsp;
              <ClipboardCopy copyText={copyText}></ClipboardCopy>
            </header>
            <div className="contract-field">
              <span className="contract-key">Price:</span>
              <span className="contract-value" style={style}>
                ISK {millify(contract.price, { precision: 3 })}
                {indicator}
              </span>
            </div>
            <div className="contract-field">
              <span className="contract-key">Volume:</span>
              <span className="contract-value">
                m3 {millify(contract.volume, { precision: 3 })}
              </span>
            </div>
            <div className="contract-field">
              <span className="contract-key">Issuer:</span>
              <span className="contract-value">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://evewho.com/character/${issuer.character_id}`}
                  className="contract-link"
                >
                  {issuer.name} ↗
                </a>
              </span>
            </div>
            <div className="contract-field">
              <span className="contract-key">Issuing Corp:</span>
              <span className="contract-value">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://evewho.com/corporation/${issuingCorporation.corporation_id}`}
                  className="contract-link"
                >
                  {issuingCorporation.name} ↗
                </a>
              </span>
            </div>
            <div className="contract-field">
              <span className="contract-key">Location: </span>
              <span className="contract-value">
                {structure && structure.name}
              </span>
            </div>
            <div className="contract-field">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://appraisal.eve-hobbes.com"
                className="contract-link"
              >
                EvePraisal ↗
              </a>
            </div>
            <EvePraisal
              contract={contract}
              contractItems={contractItems}
              evePraisal={evePraisal}
              setEvePraisal={setEvePraisal}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                padding: "0 1rem"
              }}
            >
              <img
                alt={`${issuingCorporation.name}`}
                src={`https://images.evetech.net/corporations/${issuingCorporation.corporation_id}/logo`}
                style={{ width: "50px", paddingRight: "1rem" }}
              />
              <img
                alt={`${issuer.name}`}
                src={`https://images.evetech.net/characters/${issuer.character_id}/portrait`}
                style={{ width: "50px" }}
              />
            </div>
          </div>
          <div className="contract-contents">
            {contractItems.map((item, index) => (
              <div key={item.name + index} className="contract-item">
                <span>{item.name}</span>
                <span>&emsp;x{item.quantity}</span>
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      return (
        <div className="contract">
          <header className="contract-header">
            {`Add a contract by appending ?contract_id=<contract_id> to the url.`}
          </header>
        </div>
      );
    }
  };

  return <div className="App">{renderContract()}</div>;
};

export default Contracts;

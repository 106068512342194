import React, { useEffect, useState } from "react";
import { ReactComponent as Providence } from "../assets/providence.svg";
import { ReactComponent as Hulk } from "../assets/hulk.svg";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { postData } from "../utils/fetch";
import settings from "../evehobbes.client.settings";

const Callback = () => {
  const authCode = new URL(window.location.href).searchParams.get("code");
  const [loading, setLoading] = useState(true);
  const [characterName, setCharacterName] = useState("");

  const StyledProvidence = styled(Providence)`
    width: 100%;
    margin: 4rem 0;
  `;

  const StyledHulk = styled(Hulk)`
    width: 100%;
    margin: 4rem 0;
  `;

  const url = `${settings.server_endpoint}/eve-industry/auth`;

  const submitAuthCode = async () => {
    try {
      const response = await postData(url, {
        authCode: authCode
      });
      setCharacterName(response.characterName);
      setLoading(false);
    } catch (e) {
      console.log(`Failed to post data: ${e}`);
      setLoading(false);
    }
  };

  useEffect(() => {
    submitAuthCode();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        {loading && (
          <div>
            <StyledHulk className="ld ld-damage" style={{ color: "red" }} />
          </div>
        )}
        {authCode && !loading ? (
          <div>
            <StyledProvidence className="ld ld-move-ltr" />
            Authenticated Character:{" "}
            <span style={{ color: "#6AFF33", fontWeight: "bold" }}>
              {characterName}
            </span>
          </div>
        ) : (
          <div>
            <StyledHulk className="ld ld-damage" style={{ color: "red" }} />
            Couldn't Authenticate - try again later or pm neo
          </div>
        )}
        <div>
          <Link
            style={{
              textDecoration: "none",
              color: "#DC33FF",
              border: "1px solid #DC33FF",
              padding: "1rem",
              margin: "1rem",
              display: "flex",
              borderRadius: "5px"
            }}
            to="/"
          >
            Authenticate Another?
          </Link>
        </div>
      </header>
    </div>
  );
};

export default Callback;
